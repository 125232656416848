import React from "react";
import Quotation from "@assets/quotation.svg";
import "./index.scss";

export default function Slide(props) {
  const { content, activeIndex } = props;

  const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
  const PUBLIC_ASSETS_SNIPPET = process.env.REACT_APP_PUBLIC_ASSETS_SNIPPET;
  const TESTIMONIALS_BUCKET = process.env.REACT_APP_TESTIMONIALS_BUCKET;
  const TESTIMONIALS_IMAGE_URL_BASE = `${SUPABASE_URL}/${PUBLIC_ASSETS_SNIPPET}/${TESTIMONIALS_BUCKET}`;

  return (
    <React.Fragment>
      <div 
        className="slide"
        style={{ transform: `translateX(-${activeIndex * 100}%)`}}
      >
        <div className="slide-image">
          <img alt="slide" src={`${TESTIMONIALS_IMAGE_URL_BASE}/${content.image_url}`} />
        </div>
        <div className="quotation-wrapper">
          <img alt="quotation" src={Quotation} />
          <div className="quotation">
            <p>{content.quotation}</p>
          </div>
          <div className="quote-attribution">
            <div className="quote-dash" />
            <div className="quote-author">{content.author}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}