import React, { useState, useEffect, useRef } from "react";
import NavLogo from "@assets/top_logo_white.png";
import { NAV_LINKS_DATA } from "@constants/navLinksData";
import "./index.scss";

export default function Nav(props) {
  const { navType } = props;
  const [showStickyNav, setShowStickyNav] = useState(false);
  const [mobileNavIsActive, setMobileNavIsActive] = useState(false);
  const navLinkRefs = useRef([]);
  
  useEffect(() => {
    /* 
      After the component renders, we want to add event listeners such that
      when a link is clicked on the mobile nav, the nav toggles away.
    */
    const navLinks = navLinkRefs.current;
    navLinks.forEach(n => n.addEventListener("click", closeMobileNav));

    /* 
      The sticky nav is hidden when viewing the top of the page. It only comes 
      into view when the About section comes into view.
    */
    if (navType === "sticky") window.addEventListener("scroll", handleScroll);

    return () => {
      navLinks.forEach(n => n.removeEventListener("click", closeMobileNav));
      window.removeEventListener("scroll", handleScroll);
    }
  });

  function closeMobileNav() {
    setMobileNavIsActive(false);
    document.body.classList.remove('lock-scroll');
  }

  function toggleMobileNav() {
    setMobileNavIsActive(!mobileNavIsActive);
    document.body.classList.toggle('lock-scroll');
  }

  function handleScroll() {
    const aboutHeroPositionY = window.innerHeight;
    if (window.scrollY < aboutHeroPositionY - 75) setShowStickyNav(false);
    else setShowStickyNav(true);
  }

  return (
    <React.Fragment>
      <div className={`nav-container ${navType === "sticky" ? "sticky" : ""} ${navType === "sticky" ? (!showStickyNav ? "hidden" : "visible") : ""}`}>
        <div className="nav-wrapper">
          <div className="nav-logo-container">
            <a href="/#">
              <img src={NavLogo} alt="logo" />
            </a>
          </div>
          <div className={`nav-links-container ${mobileNavIsActive ? "active" : ""}`}>
            {NAV_LINKS_DATA.map((navLink, key) => (
              <a 
                key={key}
                href={navLink.url} 
                ref={(e) => {navLinkRefs.current[key] = e}} 
                className={`${key !== NAV_LINKS_DATA.length - 1 ? "nav-link" : "button-primary"}`}
              >
                {navLink.displayName}
              </a>
            ))}
          </div>
          <div className="menu-button" onClick={toggleMobileNav}>
            <div className="menu-icon"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}