import React, { useEffect, useRef } from "react";
import { SITE_IMAGE_URL_BASE } from "@constants/urls";
import { addActiveToNavOnIntersection } from "@utils/addActiveToNavOnIntersection";
import "./index.scss";

export default function About(props) {
  const { aboutHeroImage, aboutContentImage1, aboutContentImage2 } = props;
  const sectionRef = useRef(null);

  // Use IntersectionObserver to highlight Nav elements based on scroll position
  useEffect(() => {
    let observer;
    if (sectionRef.current !== undefined) {
      const options = { threshold: 0.25 };
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => addActiveToNavOnIntersection(entry));
      }, options);
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [sectionRef])

  return (
    <React.Fragment>
      <div id="about" ref={sectionRef}>
        <div className="section about-hero">
          <h1 >A community of students, run by students.</h1>
          <img alt="lion dance team los angeles ucla" src={`${SITE_IMAGE_URL_BASE}/${aboutHeroImage}`} />
        </div>
        <div className="section about-content">
          <div className="section about-text-wrapper">
            <h2>Who are we?</h2>
            <p>We are a Chinese lion dance team at UCLA affiliated with the Association of Chinese Americans 
              (ACA). Founded in 2001 originally as an aspect for ACA's Chinese American Culture Night (CACN), 
              the continued growth of the team allows us to entertain and perform at many locales and 
              celebrations in the Los Angeles area. <br /><br />

              In addition to collegiate performances such as UCLA's CACN and southern California's Chinese 
              Heritage Festival, we perform at various venues and events, ranging from Lunar New Year 
              celebrations, weddings, grand openings, blessings, parades, cultural celebrations, and 
              birthday parties!
            </p>
          </div>
          <div className="section about-image-wrapper">
            <img alt="lion dance lunar new year los angeles" src={`${SITE_IMAGE_URL_BASE}/${aboutContentImage1}`} />
            <img alt="lion dance lunar new year los angeles" src={`${SITE_IMAGE_URL_BASE}/${aboutContentImage2}`} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}