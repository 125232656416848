import React, { useState, useEffect } from "react";
import Nav from "@components/Nav";
import PhotoCard from "@components/PhotoCard";
import Footer from "@components/Footer";
import { MEMBER_IMAGE_URL_BASE, NO_PHOTO_URL } from "@constants/urls";
import { supabase, getData } from "@utils/supabaseClient";
import "../index.scss";

export default function Members() {
  const [currentYear, setCurrentYear] = useState(null);
  const [coodinatorsData, setCoordinatorsData] = useState(null);
  const [activeMembersData, setActiveMembersData] = useState(null);
  const [generationsData, setGenerationsData] = useState(null);

  const supabaseSiteDataQuery = supabase
    .from("site_data")
    .select("key, value")
    .or("key.eq.current_year,key.eq.displayed_generations");
  const supabaseCoordinatorsQuery = supabase
    .from("members")
    .select("name, year, major, image_url")
    .eq("role", "Coordinator")
    .order("name");
  const supabaseActiveMembersQuery = supabase
    .from("members")
    .select("name, year, major, image_url")
    .or("role.is.null,role.neq.Coordinator")
    .eq("is_active", "true")
    .order("name");
  const supabaseGenerationsQuery = supabase
    .from("members")
    .select("name, generation")
    .order("name");

  useEffect(() => {
    getData(supabaseCoordinatorsQuery).then(result => {
      setCoordinatorsData(result.data);
    });
    getData(supabaseActiveMembersQuery).then(result => {
      setActiveMembersData(result.data);
    });
    getData(supabaseSiteDataQuery).then(result => {
      setCurrentYear(result.data[1].value);

      // Get displayed generations from Supabase
      const displayedGenerations = result.data[0].value.split(",");
      const generationHashMap = new Map();
      displayedGenerations.forEach(generation => {
        generationHashMap.set(generation, []);
      });

      // Add "Past Alumni" to Map
      generationHashMap.set("Past Alumni", []);

      // Add member data to arrays in Map
      getData(supabaseGenerationsQuery).then(result => {
        result.data.forEach(member => {
          if (!generationHashMap.has(member.generation)) {
            generationHashMap.get("Past Alumni").push(member.name);
          } else {
            generationHashMap.get(member.generation).push(member.name);
          }
        });
        generationHashMap.forEach((value, key) => {
          generationHashMap.set(key, value.join(" • "));
        });
        setGenerationsData(Array.from(generationHashMap).map(([generation, members]) => ({ generation, members })));
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Nav navType="sticky" />
      <Nav />
      <div className="members-content">
        {currentYear !== null && <h1>{currentYear}</h1>}
        <h2>Coordinators</h2>
        <div className="photo-card-grid center">
          {
            coodinatorsData !== null && coodinatorsData.map((coordinator, key) => (
              <PhotoCard 
                key={key}
                image={coordinator.image_url !== null ? `${MEMBER_IMAGE_URL_BASE}/${coordinator.image_url}` : NO_PHOTO_URL} 
                name={coordinator.name} 
                firstLine={coordinator.year} 
                secondLine={coordinator.major} 
              />
            ))
          }
        </div>
        <h2>Active Members</h2>
        <div className="photo-card-grid">
          {
            activeMembersData !== null && activeMembersData.map((member, key) => (
              <PhotoCard 
                key={key}
                image={member.image_url !== null ? `${MEMBER_IMAGE_URL_BASE}/${member.image_url}` : NO_PHOTO_URL} 
                name={member.name} 
                firstLine={member.year} 
                secondLine={member.major} 
              />
            ))
          }
        </div>
        <div className="photo-credit">Photos taken by Brian Le</div>
        <h2>Founders</h2>
        <div className="photo-card-grid">
          <PhotoCard image={`${MEMBER_IMAGE_URL_BASE}/anwerkhan.jpg`} name={"Anwer Khan"} />
          <PhotoCard image={`${MEMBER_IMAGE_URL_BASE}/bobbychin.jpg`} name={"Bobbie Chin"} />
          <PhotoCard image={`${MEMBER_IMAGE_URL_BASE}/justinchan.jpg`} name={"Justin Chan"} />
          <PhotoCard image={`${MEMBER_IMAGE_URL_BASE}/tiffanimah.jpg`} name={"Tiffani Mah"} />
        </div>
        <div className="generations-container">
          <h1>Generations</h1>
          {
            generationsData !== null && generationsData.map((item, key) => (
              <div key={key} className="generation-container">
                <h2>{item.generation}</h2>
                <p>{item.members}</p>
              </div>
          ))}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}