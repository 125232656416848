import React from "react";
import "./index.scss";

export default function PhotoCard(props) {
  const { image, name, firstLine, secondLine } = props;
  const year = firstLine;
  const major = secondLine;

  return (
    <React.Fragment>
      <div className="photo-card-container">
        <div className="photo-card-image-wrapper">
          <img src={image} alt={name} />
        </div>
        <div className="photo-card-text-wrapper">
          <p className="bold">{name}</p>
          <p>{year}</p>
          <p>{major}</p>
        </div>
      </div>
    </React.Fragment>
  );
}