import React, { useState, useEffect, useRef } from "react";
import Slide from "./Slide";
import { addActiveToNavOnIntersection } from "@utils/addActiveToNavOnIntersection";
import { supabase, getData } from "@utils/supabaseClient";
import { useSwipeable } from "react-swipeable";
import "./index.scss";

export default function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const sectionRef = useRef(null);

  const supabaseTestimonialsQuery = supabase.from("testimonials").select("*");
  
  useEffect(() => {
    getData(supabaseTestimonialsQuery).then(result => {
      setTestimonialsData(result.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use IntersectionObserver to highlight Nav elements based on scroll position
  useEffect(() => {
    let observer;
    if (sectionRef.current !== undefined) {
      const options = { threshold: 0.8 };
      observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => addActiveToNavOnIntersection(entry));
      }, options);
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, [sectionRef])

  const updateIndex = (newIndex) => {
    if (newIndex < 0) newIndex = 0;
    else if (newIndex > testimonialsData.length - 1) newIndex = testimonialsData.length - 1;
    setActiveIndex(newIndex);
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  /* 
    Slide Mask is the viewport of the carousel. We pass activeIndex to each Slide so
    when we change the index, all the Slides can move together.
  */
  return (
    <React.Fragment>
      <div id="testimonials" ref={sectionRef} className="section testimonials">
        <div {...swipeHandlers}>
          <div className="slide-mask">
            {testimonialsData !== null && testimonialsData.map((content, i) => 
              <Slide key={i} content={content} activeIndex={activeIndex} />
            )}
          </div>
          <div className="left-arrow" onClick={() => updateIndex(activeIndex - 1)} />
          <div className="right-arrow" onClick={() => updateIndex(activeIndex + 1)} />
          <div className="slide-nav">
            {testimonialsData !== null && testimonialsData.map((content, i) => 
              <div 
                key={i} 
                className={`slider-dot ${i === activeIndex ? "active" : ""}`} 
                onClick={() => updateIndex(i)}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}