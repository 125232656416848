import React, { useState, useEffect } from "react";
import Nav from "@components/Nav";
import PhotoCard from "@components/PhotoCard";
import Footer from "@components/Footer";
import { LION_IMAGE_URL_BASE } from "@constants/urls";
import { supabase, getData } from "@utils/supabaseClient";
import "../index.scss";

export default function Lions() {
  const [activeLionsData, setActiveLionsData] = useState(null);
  const [retiredLionsData, setRetiredLionsData] = useState(null);

  const supabaseActiveLionsQuery = supabase
    .from("lions")
    .select("name, date_awakened, is_active, image_url")
    .eq("is_active", "true")
    .order('id', { ascending: false });
  const supabaseRetiredLionsQuery = supabase
    .from("lions")
    .select("name, date_awakened, is_active, image_url")
    .eq("is_active", "false")
    .order('id', { ascending: false });
  
    useEffect(() => {
      getData(supabaseActiveLionsQuery).then(result => {
        setActiveLionsData(result.data);
      });
      getData(supabaseRetiredLionsQuery).then(result => {
        setRetiredLionsData(result.data);
      });
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <React.Fragment>
      <Nav navType="sticky" />
      <Nav />
      <div className="members-content">
        <h1>Active Lions</h1>
        <div className="photo-card-grid center">
          {
            activeLionsData !== null && activeLionsData.map((lion, key) => (
              <PhotoCard 
                key={key}
                image={`${LION_IMAGE_URL_BASE}/${lion.image_url}`} 
                name={lion.name} 
                firstLine={`Awakened ${lion.date_awakened}`} 
              />
            ))
          }
        </div>
        <h1>Retired Lions</h1>
        <div className="photo-card-grid">
          {
            retiredLionsData !== null && retiredLionsData.map((lion, key) => (
              <PhotoCard 
                key={key}
                image={`${LION_IMAGE_URL_BASE}/${lion.image_url}`} 
                name={lion.name} 
                firstLine={`Awakened ${lion.date_awakened}`} 
              />
            ))
          }
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}