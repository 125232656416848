const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const PUBLIC_ASSETS_SNIPPET = process.env.REACT_APP_PUBLIC_ASSETS_SNIPPET;

const MEMBERS_BUCKET = process.env.REACT_APP_MEMBERS_BUCKET;
const LIONS_BUCKET = process.env.REACT_APP_LIONS_BUCKET;
const SITE_IMAGES_BUCKET = process.env.REACT_APP_SITE_IMAGES_BUCKET;

const NO_PHOTO_FILE = process.env.REACT_APP_NO_PHOTO_FILE;

const IMAGE_URL_BASE = `${SUPABASE_URL}/${PUBLIC_ASSETS_SNIPPET}`;

export const MEMBER_IMAGE_URL_BASE = `${IMAGE_URL_BASE}/${MEMBERS_BUCKET}`;
export const LION_IMAGE_URL_BASE = `${IMAGE_URL_BASE}/${LIONS_BUCKET}`;
export const NO_PHOTO_URL = `${MEMBER_IMAGE_URL_BASE}/${NO_PHOTO_FILE}`;
export const SITE_IMAGE_URL_BASE = `${IMAGE_URL_BASE}/${SITE_IMAGES_BUCKET}`;