import React, { useState, useEffect } from "react";
import Nav from "@components/Nav";
import OpeningHero from "@components/OpeningHero";
import About from "@components/About";
import Testimonials from "@components/Testimonials";
import Contact from "@components/Contact";
import Footer from "@components/Footer";
import { supabase, getData } from "@utils/supabaseClient";

export default function Landing() {
  const [siteData, setSiteData] = useState(null);

  const supabaseSiteDataQuery = supabase
    .from("site_data")
    .select("key, value")
    .or("key.eq.about_hero_image,key.like.about_content_image_%,key.eq.contact_image");
  
  useEffect(() => {
    getData(supabaseSiteDataQuery).then(result => {
      setSiteData(Object.assign({}, ...result.data.map(x => ({ [x.key]: x.value }))));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    siteData !== null && <React.Fragment>
      <Nav navType="sticky" />
      <OpeningHero />
      <About
        aboutHeroImage={siteData["about_hero_image"]}
        aboutContentImage1={siteData["about_content_image_1"]}
        aboutContentImage2={siteData["about_content_image_2"]}
      />
      <Testimonials />
      <Contact contactImage={siteData["contact_image"]} />
      <Footer />
    </React.Fragment>
  );
}