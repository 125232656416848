import React from "react";
import Nav from "@components/Nav";
import HeroLogo from "@assets/logo.png";
import FrontPageImage from "@assets/hero.png";
import "./index.scss";

export default function OpeningHero() {
  return (
    <React.Fragment>
      <div className="section front-page">
        <Nav />
        <div className="hero-logo">
          <img src={HeroLogo} alt="front" />
        </div>
        <div className="hero-content">
          <h1>ACA Lion Dance</h1>
          <h2>UCLA's premier lion dance troupe!</h2>
          <div className="button-container">
            <a className="button-clear" href="/#about">Learn More</a>
            <a className="button-primary" href="/#contact">Book Now</a>
          </div>
        </div>
        <div className="hero-image">
          <img src={FrontPageImage} alt="front" />
        </div>
      </div>
    </React.Fragment>
  );
}