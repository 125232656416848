export function addActiveToNavOnIntersection(entry) {
  const navElements = [...document.querySelectorAll(".nav-link")].filter(item => {
    return item.href.includes(`#${entry.target.id}`);
  });
  if (entry.isIntersecting) {
    navElements.forEach(navElement => {
      if (!navElement.classList.contains("active")) {
        navElement.classList.add("active")
      }
    });
  } else {
    navElements.forEach(navElement => {
      if (navElement.classList.contains("active")) {
        navElement.classList.remove("active")
      }
    });
  }
}