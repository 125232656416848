export const NAV_LINKS_DATA = [
  {
    displayName: "Home",
    url: "/#"
  },
  {
    displayName: "About",
    url: "/#about"
  },
  {
    displayName: "Testimonials",
    url: "/#testimonials"
  },
  {
    displayName: "Book Now",
    url: "/#contact"
  }
];