import React, { useState } from "react";
import { SITE_IMAGE_URL_BASE } from "@constants/urls";
import { supabase } from "@utils/supabaseClient";
import "./index.scss";

export default function Contact(props) {
  const { contactImage } = props;
  const [loading, setLoading] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeyPot, setHoneyPot] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");
  const [submitMessageClass, setSubmitMessageClass] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitMessage("");
    setSubmitMessageClass("");

    if (honeyPot !== "") {
      return;
    }

    if (name === "" || email === "" || message === "") {
      setSubmitMessage("Please fill out all fields");
      setSubmitMessageClass("error");
      return;
    }

    setLoading(true);
    supabase.from("contact_form_submissions").insert({
      name: name,
      email: email,
      message: message
    }).then(response => {
      console.log(response);
      if (response.error !== null) {
        setSubmitMessage("Error: please try again later");
        setSubmitMessageClass("error");
      } else {
        setSubmitMessage("We'll get back to you soon!");
        setSubmitMessageClass("success");
      }
    }).finally(() => {
      setName("");
      setEmail("");
      setMessage("");
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <div id="contact" className="contact">
        <div className="contact-content">
          <div className="contact-text-wrapper">
            <h1>Contact us!</h1>
            <p>
              If you would like to book us for a performance, you can fill out the form 
              here or contact us by email. We look forward to hearing from you!
            </p>
            <p className="center">Email | <a href="mailto:acaliondance@gmail.com">acaliondance@gmail.com</a></p>
          </div>
          <div>
            <img src={`${SITE_IMAGE_URL_BASE}/${contactImage}`} alt="contact for lion dance performance request" />
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name
            <input type="text" onChange={event => setName(event.target.value)} value={name} />
          </label>
          <input className="subject" onChange={event => setHoneyPot(event.target.value)} value={honeyPot} />
          <label>
            Email
            <input type="email" onChange={event => setEmail(event.target.value)} value={email} />
          </label>
          <label>
            Message
            <textarea 
              type="text" 
              placeholder="Please include date, time, location (venue), and type of event!"
              onChange={event => setMessage(event.target.value)}
              value={message}
            />
          </label>
          <p className={`submit-message ${submitMessageClass}`}>{submitMessage}</p>
          <input type="submit" className={`${loading ? "loading" : ""}`} value="Submit" />
        </form>
      </div>
    </React.Fragment>
  );
}