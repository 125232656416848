import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Landing from "@pages/Landing";
import Members from "@pages/Team/Members";
import Lions from "@pages/Team/Lions";

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/members" exact component={Members} />
        <Route path="/lions" exact component={Lions} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}