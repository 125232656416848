import React from "react";
import Logo from "@assets/logo.png";
import { NAV_LINKS_DATA } from "@constants/navLinksData";
import "./index.scss";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer-content">
          <div className="footer-logo-container">
            <a href="/#">
              <img src={Logo} alt="logo" />
            </a>
          </div>
          <div className="footer-nav">
            <div className="footer-col">
              <h1>ACA Lion Dance</h1>
                {NAV_LINKS_DATA.map((navLink, key) => (
                  <a 
                    key={key}
                    href={navLink.url} 
                  >
                    {navLink.displayName}
                  </a>
                ))}
            </div>
            <div className="footer-col">
              <h1>Team</h1>
              <a href="/members">Members</a>
              <a href="/lions">Lions</a>
            </div>
            <div className="footer-col">
              <h1>More</h1>
              <a 
                href="https://www.instagram.com/acaliondance" 
                target="_blank" 
                rel="noopener noreferrer" 
              >
                Instagram
              </a>
              <a 
                href="https://www.youtube.com/user/acaliondance" 
                target="_blank" 
                rel="noopener noreferrer" 
              >
                YouTube
              </a>
            </div>
          </div>
        </div>
        <div className="copyright">
          © {currentYear} by ACA Lion Dance. All rights reserved. Updated 11/11/2022 | Designed by Brian Le '22
        </div>
      </div>
    </React.Fragment>
  );
}